body {
  min-height: 100vh;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cust-border {
  border: 4px dashed rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: 10px;
}

.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

.tippy-tooltip.tomato-theme {
  background-color: tomato;
  color: yellow;
}

.fix-thai input {
  height: 0.9em;
  font-size: 0.6rem;
}

.fix-thai label {
  height: 20px;
  font-size: 0.7rem;
  line-height: 0.5rem;
}

.mui-textfield input {
  height: 19px;
  font-size: 0.7rem;
}

.trapezium {
  border-top: 15px solid #80bf54;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
}

@media screen and (min-width: 768px) {
  .trapezium {
    border-top: 25px solid #80bf54;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
  }
}

/* Scroll animation */
.scroller {
  scroll-behavior: smooth;
}

/* Scroll animation */
